/* here you can put your own css to customize and override the theme */

@import '../../global/_variables';
// global metronic framework variables
@import '../../global/components/_mixins';
// global metronic framework mixings

@import '_variables'; // theme level variables

@mixin reset-container() {
    > .container {
        width: 100%;
        max-width: none !important;
        margin: 0 !important;
    }
}

@import '../../../../node_modules/datatables-responsive/css/responsive.dataTables'; // global metronic framework mixings

/***
Allgemeines
***/

body {
    line-height: 1.6;
    -webkit-font-smoothing: antialiased;
}

b,
strong {
    font-weight: bold;
}

p, li, h1, h2, h3, h4, h5, h6 {
    max-width: 1000px;
}

h4.panel-title,
.text-center h5 {
    max-width: unset;
}

table li {
    max-width: unset;
}


.nav p, .nav li {
    max-width: 100%;
}


/* styles for better image quality of canvas elements */
canvas,
img {
    image-rendering: crisp-edges;
    image-rendering: -moz-crisp-edges;
    image-rendering: -webkit-optimize-contrast;
    image-rendering: optimize-contrast;
    -ms-interpolation-mode: nearest-neighbor;
}


.text-ellipsis {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.text-red {
    color: red;
}

.text-large {
    font-size: 30px !important;
}

.font-red-intense {
    //color: #e35b5a !important;
    color: #bf0000 !important;
}

.page-spinner-bar > div,
.block-spinner-bar > div {
    background: #bf0000;
}

.btn.red:not(.btn-outline),
.btn.red:not(.btn-outline):hover {
    background-color: #bf0000;
    border-color: #bf0000;
}

.btn.btn-outline.red {
    border-color: #bf0000;
    color: #bf0000;
}

.btn.btn-outline.red:hover,
.btn.btn-outline.red:active,
.btn.btn-outline.red:active:hover,
.btn.btn-outline.red:active:focus,
.btn.btn-outline.red:focus,
.btn.btn-outline.red.active {
    border-color: #bf0000;
    background-color: #bf0000;
}

.progress-bar-danger {
    background-color: #bf0000;
}

table.dataTable .btn {
    margin: 4px 4px 4px 0;

}

.btn:not(.md-skip):not(.bs-select-all):not(.bs-deselect-all) {
    padding: 8px 9px 7px 9px;
}

.btn-small {
    min-width: 100px;
}

/***
Bootstrap Growl
***/

.bootstrap-growl {
    padding-right: 30px;

    .close {
        position: relative;
        top: -2px;
        right: -15px;
        color: inherit;
    }
}

/***
Formulare
***/

.user-login-5 .form-group.has-error {
    border: 0 none !important;
}

.form-group.form-md-line-input .form-control.edited:not([readonly]) ~ label:after,
.form-group.form-md-line-input .form-control.edited:not([readonly]) ~ .form-control-focus:after,
.form-group.form-md-line-input .form-control:focus:not([readonly]) ~ label:after,
.form-group.form-md-line-input .form-control:focus:not([readonly]) ~ .form-control-focus:after,
.form-group.form-md-line-input.has-error .form-control.edited:not([readonly]) ~ label:after,
.form-group.form-md-line-input.has-error .form-control.edited:not([readonly]) ~ .form-control-focus:after,
.form-group.form-md-line-input.has-error .form-control.form-control-static ~ label:after,
.form-group.form-md-line-input.has-error .form-control.form-control-static ~ .form-control-focus:after,
.form-group.form-md-line-input.has-error .form-control:focus:not([readonly]) ~ label:after,
.form-group.form-md-line-input.has-error .form-control:focus:not([readonly]) ~ .form-control-focus:after,
.form-group.form-md-line-input.has-error .form-control.focus:not([readonly]) ~ label:after,
.form-group.form-md-line-input.has-error .form-control.focus:not([readonly]) ~ .form-control-focus:after {
    background: #bf0000;
}

.user-login-5 .form-group.form-md-line-input.has-error .form-control,
.form-group.form-md-line-input.has-error .form-control,
.md-checkbox label > .check {
    border-color: #bf0000;
}

.form-group.form-md-line-input.has-error label {
    color: #bf0000;
}

.form-horizontal .form-group.form-md-line-input .form-control ~ .form-control-focus {
    position: relative;
    left: 0;
    right: 0;
}

/***
Portlets
***/

.portlet.light .btn.btn-circle.btn-icon-only.btn-default:hover,
.portlet.light .btn.btn-circle.btn-icon-only.btn-default.active {
    background: #bf0000;
    border-color: #bf0000;
}
.page-content {
    position: relative;
}


/***
Login
***/

.state-login {
    #header,
    #footer {
        display: none;
    }

    #mainContent {
        .page-head {
            display: none;
        }

        .page-content {
            position: relative;
            padding: 0;
        }

        .page-content > .container-fluid {
            padding: 0;
        }
    }
}

.user-login-5 .login-container > .login-content > .login-form .form-control {
    margin-bottom: 0;
}

@media (max-width: 1023px) {
    .user-login-5 .login-logo.login-6 {
        margin-bottom: 0;
    }
}

.forget-password {
    margin-right: 6px
}

.register {
    margin-left: 6px
}

.login-footer {
    text-align: center;

    @media (min-width: $screen-sm-min) {
        .col-xs-12,
        .col-sm-6 {
            text-align: left;
        }

        .col-sm-6 + .col-sm-6 {
            text-align: right;
        }
    }

    p {
        margin: 0;
    }
}

.teaser-pd {
    position: absolute;
    right: 12%;
    bottom: 10%;
    padding: 20px 35px;
    background: rgba(0,0,0,.75);
    color: #fff;
}

.teaser-pd span {
    display: block;
}

.teaser-pd .teaser-headline {
    font-size: 21px;
    line-height: 1.2;
}

.teaser-pd .teaser-url, .teaser-pd .teaser-url a {
    font-weight: 300;
    padding-top: 6px;
    text-decoration: none !important;
    color: #fff !important;
}

#teaser-aktion {
    margin-top: 19%;
    padding: 23px 80px 25px;
    text-decoration: none !important;
}

@media (max-width: 1365px) {
    #teaser-aktion {
        margin-top: 80px;
        padding-left: 40px;
        padding-right: 40px;
    }
}

#teaser-aktion h3 {
    margin-top: 0;
    margin-bottom: 15px;
}

#teaser-aktion + .login-content {
    margin-top: 15%;
}

@media (max-width: 1365px) {
    #teaser-aktion + .login-content {
        margin-top: 40px;
    }
}

/***
_header.scss
***/

.page-header {
    /* Page Header Top */
    .page-header-top {
        /* Logo */
        .page-logo .logo-default {
            margin-top: 24px;
        }

        /* Menu Toggler */
        .menu-toggler {
            background: 0 none;
            @include opacity(1);
            @include opacity(1);
            margin: 13px 0 0;
            width: 40px;
            height: 50px;

            i {
                font-size: 19px;
                width: 40px;
                text-align: center;
                color: #C1CCD1;
                line-height: 30px;
            }
        }

        .top-menu .navbar-nav > li.dropdown {
            margin: 0;
            padding: 0;
            height: 50px;
            display: inline-block;
        }

        .top-menu .navbar-nav > li.dropdown > .dropdown-toggle {
            margin: 0;
            padding: 0;
            width: 40px;
            height: 50px;
        }

        .top-menu .navbar-nav > li.dropdown-language > .dropdown-toggle {
            padding: 15px 10px;
        }

        .top-menu .navbar-nav > li.dropdown-language > .dropdown-toggle > img {
            margin-bottom: 0;
        }

        .top-menu .navbar-nav > li.dropdown.dropdown-language > .dropdown-toggle {
            padding: 15px 10px;
        }

        .top-menu .navbar-nav > li.dropdown > .dropdown-menu {
            padding: 5px 0;
        }

        .dropdown-menu li {
            &.content {
                h3,
                span {
                    color: #6f6f6f;
                    padding: 8px 16px;
                    text-decoration: none;
                    display: block;
                    clear: both;
                    font-weight: 300;
                    line-height: 18px;
                    white-space: nowrap;
                }

                h3 {
                    margin: 0;
                }
            }

            &.border-top {
                border-top: 1px solid #ccc;
                padding-top: 5px;
                margin-top: 5px;
            }

            &.border-bottom {
                border-bottom: 1px solid #ccc;
                padding-bottom: 5px;
                margin-bottom: 5px;
            }
        }
    }

    /* Page Header Menu */
    .page-header-menu {
        background: #fff;
    }
}


@media (max-width: $menu-breakpoint-min) {
    .page-header {
        padding: 0;
        clear: both;
        height: auto;
    }

    .page-header .page-header-menu .hor-menu .navbar-nav > li:hover > a {
        background: none !important;
        color: #E35B5A !important;
    }

    .page-header .page-header-top .menu-toggler {
        display: block;
    }
}

@media (min-width: $menu-breakpoint-max) {
    /* Page header */
    .page-header {
        height: 75px;
        .dropdown.active{
            background: #bf0000;
        }
        /* Page Header Menu */
        .page-header-menu {
            display: block;
            height: 75px;
            clear: none;
            float: left;

            /* Mega menu */
            .hor-menu {
                .navbar-nav {
                    /* Classic menu */
                    > li {
                        > a {
                            padding: 28px 18px 27px 18px;
                            color: #666;

                            > i {
                                color: #666;
                            }
                        }

                        &:hover > a,
                        &.open > a,
                        > a:hover,
                        > a:focus,
                        > a:active {
                            color: #fff;
                            background: #bf0000 !important;

                            > i {
                                color: #fff;
                            }
                        }

                        &.active,
                        &.current {
                            > a,
                            > a:hover {
                                color: #fff;
                                background: #bf0000;

                                > i {
                                    color: #fff;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: $menu-breakpoint-min) {
    /* Page header */
    .page-header {
        .page-header-menu {
            background: #fff;
            padding-bottom: 20px;

            /* Mega menu */
            .hor-menu {
                .navbar-nav {
                    /* Classic menu */
                    > li {
                        border-bottom: 1px solid #F0F0F0;

                        &:last-child {
                            border-bottom: 0;
                        }

                        &.open,
                        &.active,
                        &.current {
                            > a,
                            > a:hover {
                                color: #bf0000 !important;
                                background: #fff !important;

                                > i {
                                    color: #bf0000 !important;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: $screen-xs-min) {
    .page-header {
        /* Top navigation menu */
        .page-header-top .top-menu {
            clear: none;
            margin-top: 13px;
        }
    }
}

/***
_content.scss
***/

.page-head {
    background: #bf0000;

    .page-title {
        > h1 {
            color: #fff;
        }
    }
}

@media (max-width: $menu-breakpoint-min) {
    .page-head {
        background: #bf0000;
    }

    .page-content {
        position: relative;
        padding-top: 15px;
    }

    .page-header .page-header-menu .hor-menu .navbar-nav > li > a{
        color: #666 !important;
    }
}

.page-header .page-header-top .menu-toggler i,
.page-header .page-header-top .top-menu ul.navbar-nav > li > a > i,
.page-header .page-header-menu .hor-menu ul.navbar-nav > li > a > i,
.page-header .page-header-top .top-menu ul.navbar-nav > li.dropdown > .dropdown-toggle > i {
    font-size: $header-top-menu-icon-font-size;
    color: #666;
    line-height: 50px;
    width: 40px;
}

/*
.page-header .page-header-top .top-menu ul.navbar-nav > li.dropdown-language > .dropdown-toggle > img {
    margin-bottom: 4px;
}
*/

/***
_components.scss
***/

.page-header .page-header-top .top-menu .navbar-nav > li.dropdown-extended .dropdown-menu:after {
    border-bottom-color: #fff;
}

@media (max-width: $menu-breakpoint-min) { /* 991px */
    .nav.pull-right > li > .dropdown-menu,
    .nav > li > .dropdown-menu.pull-right {
        right: -40px;
        left: auto;
    }

    .page-header .page-header-top .top-menu .navbar-nav > li.dropdown > .dropdown-menu:before {
        right: 47px;
    }

    .page-header .page-header-top .top-menu .navbar-nav > li.dropdown > .dropdown-menu:after {
        right: 48px;
    }
}

/***
Footer
***/

.page-prefooter {
    text-align: center;

    @media (min-width: $screen-sm-min) {
        .col-sm-6 {
            text-align: left;
        }

        .col-sm-6 + .col-sm-6 {
            text-align: right;
        }
    }

    padding: 30px 0 20px;

    a {
        color: #a2abb7;
    }
    .pricelist{
        text-align: left;
    }
}

.page-footer {
    text-align: center;

    @media (min-width: $screen-sm-min) {
        text-align: left;
    }

    p {
        margin: 0;
    }
}

/***
Neuer Auftrag
***/

/* myWall */

.wallpaper-img-wrapper {
    margin-bottom: 10px;
    &.disabled{
        opacity: 0.5;
    }

    &.unselected {
        position: relative;
        height: 100%;
        width: 100%;

        &:after {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            background: rgba(255, 255, 255, .85);
            height: 100%;
            width: 100%;
            border-radius: 4px;
        }
    }
    &.selected {
        position: relative;
        height: 100%;
        width: 100%;
        opacity: 1;

        &:after {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            border: 4px solid #333;
            height: 100%;
            width: 100%;
            border-radius: 4px;
        }
    }
}
.mh-50{
    min-height: 50px;
}
.mh-100{
    min-height: 100px;
}

.mh-300{
    min-height: 300px;
}


/* imgUpload */
#imgUpload {
    .fileinput {
        margin-top: 0;
        margin-bottom: 20px;
        text-align: center;

        .thumbnail {
            height: auto;
            margin-bottom: 9px;
            max-width: 800px;
            margin-left: auto;
            margin-right: auto;

            p {
                margin: 51px auto;
                padding: 0;
                width: 95%;
                text-align: center;
                color: #888;
                line-height: 1.42857 !important;
            }

        }

    }
}

/* myImages */
#myImages, .myImages, .vehicle-area {
    .imagecount-status{
        font-size: 20px;
        font-weight: bold;
    }
    .preview-img-wrapper{
            position: relative;
            &.unselected {
                position: relative;
                height: 100%;
                width: 100%;

                &:after {
                    content: "";
                    position: absolute;
                    top: 0;
                    left: 0;
                    background: rgba(255, 255, 255, .85);
                    height: 100%;
                    width: 100%;
                    border-radius: 4px;
                }
            }
        }

    .thumbnail.img-preview {
        //padding: 0;
        //border: 0 none;

        .caption {
            padding-left: 0;
            padding-right: 0;
        }
        .job-guide-mini{
            float: left;
            width: calc(40px * 1.33);
            height: auto;
        }
        .btn-trash{
            float:right;
            width: auto;


        }

        p {
            margin: 0 0 9px 0;
        }

        canvas {
            max-width: 100%;
            height: auto;
            display: block;
        }

        .progress {
            margin-bottom: 0;
            margin-top: 9px;
        }
        .wrong-ratio{
            border: 3px solid red;
        }
        .wrong-image, .job-guide-overlay{
            position: absolute;
            width: 100%;
            max-width: 100%;
            height: auto;
            top: 0;
            right:0;
            left: 0;
        }
        .wrong-image{
            z-index: 30;
        }

        .job-guide-overlay{
            z-index: 10;
        }
        .job-guide-overlay-top{
            z-index: 20;
            display: block !important;
        }

        .upload-image{

        }

        .delete-image{
            position: absolute;
            max-width: 100%;
            max-height: 100%;
            width: 100%;
            top: 0;
            left:0;
            z-index: 40;

        }
        .btn-delete-image{
            position: absolute;
            opacity: 1;
            top: 5px;
            right: 5px;
            padding: 3px;

            border:none;
            font-size: 10px;
        }

        @media (min-width: 769px) {
            .btn-delete-image {
                font-size: 18px;
                padding: 5px;
            }
        }

        .min-height{
            position: absolute;
            max-width: 100%;
            max-height: 100%;
            top: 0;
            left:0;
            z-index: 10;
        }

        .icon-checked{
            z-index: 5;
            position: absolute ;
            top: 10px;
            left: 30px;
        }

        .unselected {
            position: relative;
            height: 100%;
            width: 100%;

            &:after {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                background-color: rgba(255, 255, 255, .85);
                height: 100%;
                width: 100%;

            }
        }
    }

    .col-xs-6.col-sm-3,
    .col-xs-6.col-sm-3.col-lg-2{
        &:nth-child(2n+1) {
            padding-left: 15px;
            padding-right: 5px;
        }

        &:nth-child(2n) {
            padding-left: 5px;
            padding-right: 15px;
        }

        .thumbnail {
            //margin-bottom: 15px;
            border: 0 none;
            padding: 0;
        }

        @media (min-width: $screen-sm-min) {
            &:nth-child(4n+1) {
                padding-left: 15px;
                padding-right: 0;
            }

            &:nth-child(4n+2) {
                padding-left: 10px;
                padding-right: 5px;
            }

            &:nth-child(4n+3) {
                padding-left: 5px;
                padding-right: 10px;
            }

            &:nth-child(4n) {
                padding-left: 0;
                padding-right: 15px;
            }

            .thumbnail {
                //margin-bottom: 10px;
            }
        }
    }
}

/***
Auftrags Übersicht
***/


table.dataTable {
    .label-default {
        &.job-state-new {
            background-color: #f0ad4e;
        }

        &.job-state-prepared {
            background-color: #f0ad4e;
        }

        &.job-state-halted {
            background-color: #f03d3e;
        }

        &.job-state-processing {
            background-color: #f0ad4e;
        }

        &.job-state-completed {
            background-color: #5cb85c;
        }

        &.job-state-downloaded {
            background-color: #5bc0de;
        }

        &.job-state-deleted {
            background-color: #bf0000;
        }
        &.job-state-complaint {
            background-color: #bf0000;
        }
    }

    > tbody > tr.child {
        ul {
            width: 100%;
        }

        @media (max-width: 539px) {
            span.dtr-title,
            span.dtr-data {
                display: block;
            }
        }

        span.dtr-title {
            min-width: 150px;
        }

        span.dtr-data {
            img {
                margin: 0;
                display: inline-block;
            }
        }
    }

    th.table-actions,
    td.table-actions {
        width: 130px;
        button{
            margin: 5px;
        }
    }
}

table.dataTable.dtr-inline.collapsed > tbody > tr > td:first-child:before,
table.dataTable.dtr-inline.collapsed > tbody > tr > th:first-child:before {
    top: 50%;
    margin-top: -8px;
}

table.dataTable.dtr-column > tbody > tr > td.control:before,
table.dataTable.dtr-column > tbody > tr > th.control:before,
table.dataTable.dtr-inline.collapsed > tbody > tr > td:first-child:before,
table.dataTable.dtr-inline.collapsed > tbody > tr > th:first-child:before {
    background-color: #D6D6D6;
}

table.dataTable.dtr-column > tbody > tr.parent td.control:before,
table.dataTable.dtr-column > tbody > tr.parent th.control:before,
table.dataTable.dtr-inline.collapsed > tbody > tr.parent > td:first-child:before,
table.dataTable.dtr-inline.collapsed > tbody > tr.parent > th:first-child:before {
    background-color: #bf0000;
}

.btn:not(.md-skip):not(.bs-select-all):not(.bs-deselect-all).btn-sm {
    padding: 6px 15px 6px 15px;
}

.table-actions {
    .btn.disabled,
    .btn[disabled],
    fieldset[disabled] .btn {
        opacity: 0;
    }
}

/***
Reklamation
***/

#complainModal {
    .md-checkbox label {
        padding: 30px 0 0 0;
        display: block;
        text-align: center;

        span {
            left: 50%;

            &.inc {
                margin-left: -30px;
            }

            &.check {
                margin-left: -5px;
            }

            &.box {
                margin-left: -10px;
            }
        }
    }
}

/***
Dashboard
***/

.CSSAnimationChart a {
    /*  opacity: 0 !important;*/
    left: 30px !important;
}

.dashboard-stat {
    &.job-new {
        background-color: lighten(#0034f0, 15%);
        .more {
            background-color: #0034f0;
        }
        .visual > i {
            opacity: 0.12;
        }
    }
    &.job-state-new {
        /*
        background-color: lighten(#777777, 15%);
        .more { background-color: #777777; }
        .visual > i { opacity: 0.07; }
        */
        background-color: lighten(#f0ad4e, 15%);
        .more { background-color: #f0ad4e; }
        .visual > i { opacity: 0.12; }
    }

    &.job-state-prepared {
        /*
        background-color: lighten(#337ab7, 15%);
        .more { background-color: #337ab7; }
        .visual > i { opacity: 0.07; }
        */
        background-color: lighten(#5cb85c, 15%);
        .more { background-color: #5cb85c; }
        .visual > i { opacity: 0.1; }
    }

    &.job-state-processing {
        background-color: lighten(#f0ad4e, 15%);
        .more { background-color: #f0ad4e; }
        .visual > i { opacity: 0.12; }
    }

    &.job-state-completed {
        background-color: lighten(#5cb85c, 15%);
        .more { background-color: #5cb85c; }
        .visual > i { opacity: 0.1; }
    }

    &.job-state-downloaded {
        background-color: lighten(#5bc0de, 15%);
        .more { background-color: #5bc0de; }
        .visual > i { opacity: 0.13; }
    }

    &.job-state-deleted {
        background-color: lighten(#bf0000, 30%);
        .more { background-color: #bf0000; }
        .visual > i { opacity: 0.08; }
    }

    .visual > i {
        color: #fff;
    }

    .details .number,
    .details .desc,
    .more {
        color: #fff;
    }

    .details {
        padding-right: 10px;

        .number {
            padding-top: 17px;
            padding-bottom: 5px;
        }

        .desc {
            font-size: 14px;
            padding: 5px;
        }
    }

    .more {
        text-transform: none;
    }
}

/***
Hilfe
***/

.panel-open > .panel-heading,
.panel-default > .panel-heading a:hover {
    background-color: #bf0000;
    color: #fff;
}

.panel-default > .panel-heading {
    padding: 0;
    border-radius: 0;

    a {
        padding: 16px 45px 16px 15px;
        display: block;
        position: relative;

        i {
            position: absolute;
            right: 15px;
            top: 50%;
            margin-top: -7px;
        }
    }
}

.panel .panel-title,
.panel .panel-body {
    font-size: inherit;
}

/* Growl */

.growl {
    position: fixed;
    top: 10px;
    right: 10px;
    float: right;
    width: 250px;
    z-index: 19999;
}

.growl-item.ng-enter {
    -webkit-animation: bounceIn 0.5s;
    animation: bounceIn 0.5s;
}

.growl-item.ng-leave {
    -webkit-animation: bounceOut 0.5s;
    animation: bounceOut 0.5s;
}

.buttons {
    margin: 220px 4px;
}

/* bootbox */
.btn.btn-primary[data-bb-handler] {
    background-color: #bf0000;
}

a.widget, a.widget:visited, a.widget:active {
    text-decoration: none;
    color: lighten(white, 50%);
}

a.widget:hover {
    text-decoration: none;
    color: lighten(white, 100%);
}

.md-checkbox label > .box.error {
    border: 2px solid #bf0000 !important;
}

//
.form-control .color-picker {
    margin-bottom: 10px;
}

#claimPreview {
    position: absolute;
    //visibility: hidden;
    height: auto;
    width: auto;
    white-space: nowrap;
}


/*override menu breakpoints */
/* $screen-mobile-desktop-switch = 1100px */

/*@media (max-width: $screen-mobile-desktop-switch){
    .page-header .page-header-top .menu-toggler {
        display: block;
    }
}*/

@media (min-width: $menu-breakpoint-max) {
    .page-header .page-header-menu {
        float: right;
    }

    .page-header .page-header-menu .hor-menu .navbar-nav > li > a {
        padding: 28px 12px 27px 12px;
    }

    /*
    .page-header .page-header-top .top-menu .navbar-nav > li.dropdown > .dropdown-toggle {
        padding: 15px 0px 14px 10px;
    }

    .page-header .page-header-top .top-menu .navbar-nav > li.dropdown-language > .dropdown-toggle {
        //padding: 16px 0px 9px 12px;
        padding: 15px 0px 14px 10px;
    }
    */

    .page-header .page-header-top .page-logo {
        width: 205px;
    }
}

ul.dropdown-menu li [class^="fa-"],
ul.dropdown-menu li [class^="glyphicon-"],
ul.dropdown-menu li [class^="icon-"],
ul.dropdown-menu li [class*=" fa-"],
ul.dropdown-menu li [class*=" glyphicon-"],
ul.dropdown-menu li [class*=" icon-"] {
    margin-right: 3px;
}

.vehicle-area {
    padding-right: 50px;
}


/*
.rotate {
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    height: 100px;
    width: 100px;
    div{
        transform: rotate(-90.0deg);
        margin-left: -5rem;
        margin-right: -5rem;
    }
}

@media (min-width: 1200px){
    .rotate {
        height: 120px;
        width: 120px;
    }
    div{
        margin-left: -6rem;
        margin-right: -6rem;
    }
}
*/
.page-wrapper-middle{
    position: relative;
}
.workInProgress{
    opacity: 0.3;
    background: #000000;
    position: absolute;
    z-index: 1;
    width: 100%;
    height: calc(100% - 62px);
    top: 62px;
    left: 0;

    text-align: center;
}

.wallpaper-disabled {
    opacity: 0.7;
}

.table-specs {
    th {
        font-weight: bold;
        background-color: #bf0000;
        color: white;
    }

    td:nth-child(2),
    td:nth-child(3),
    td:nth-child(4) {
        text-align: center;
        font-weight: bold;
        vertical-align: middle;
    }
    th:nth-child(2),
    th:nth-child(3),
    th:nth-child(4) {
        text-align: center;
    }
}

iframe.hlp-launcher-button {
    bottom: 80px !important;
}

body.dndsupport [draggable=true] {
    cursor: move;
}

.lds-spinner {
    color: official;
    display: inline-block;
    position: relative;
    width: 64px;
    height: 64px;

}

.lds-spinner div {
    transform-origin: 32px 32px;
    animation: lds-spinner 1.2s linear infinite;
}

.lds-spinner div:after {
    content: " ";
    display: block;
    position: absolute;
    top: 3px;
    left: 29px;
    width: 5px;
    height: 14px;
    border-radius: 20%;
    background: #bf0000;
}

.lds-spinner div:nth-child(1) {
    transform: rotate(0deg);
    animation-delay: -1.1s;
}

.lds-spinner div:nth-child(2) {
    transform: rotate(30deg);
    animation-delay: -1s;
}

.lds-spinner div:nth-child(3) {
    transform: rotate(60deg);
    animation-delay: -0.9s;
}

.lds-spinner div:nth-child(4) {
    transform: rotate(90deg);
    animation-delay: -0.8s;
}

.lds-spinner div:nth-child(5) {
    transform: rotate(120deg);
    animation-delay: -0.7s;
}

.lds-spinner div:nth-child(6) {
    transform: rotate(150deg);
    animation-delay: -0.6s;
}

.lds-spinner div:nth-child(7) {
    transform: rotate(180deg);
    animation-delay: -0.5s;
}

.lds-spinner div:nth-child(8) {
    transform: rotate(210deg);
    animation-delay: -0.4s;
}

.lds-spinner div:nth-child(9) {
    transform: rotate(240deg);
    animation-delay: -0.3s;
}

.lds-spinner div:nth-child(10) {
    transform: rotate(270deg);
    animation-delay: -0.2s;
}

.lds-spinner div:nth-child(11) {
    transform: rotate(300deg);
    animation-delay: -0.1s;
}

.lds-spinner div:nth-child(12) {
    transform: rotate(330deg);
    animation-delay: 0s;
}

@keyframes lds-spinner {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

.img-responsive canvas {
    max-width: 100%;
}

.bootbox h3 {
    line-height: 1.5;
}

.addon-button {
    position: absolute;

}

.form-control-inline {
    width:auto;
    display:inline-block;
}

.color-picker-inline {
    display:inline-block;
    width: auto;
    padding-top:5px;
    input{
        display: none;
    }
    color-picker{
        width: 20px;
        height: 20px;
        line-height: 2;
        margin-right: 10px;
    }
}

.state-preview360, .state-overview {
    .actions {
        position: absolute;
        z-index: 30;

        &.bottom-right {
            right: 10px;
            bottom: 10px;
            text-align: right;
        }

        &.center {
            text-align: center;
            left: calc(50% - (36.79px / 2));
            top: calc(50% - (36.79px / 2));

            @media (min-width: $screen-sm-min) {
                line-height: 42.6px;
                left: calc(50% - (42.6px / 2));
                top: calc(50% - (42.6px / 2));
            }

            @media (min-width: $screen-lg-min) {
                line-height: 45px;
                left: calc(50% - (45px / 2));
                top: calc(50% - (45px / 2));
            }
        }
    }

    .lazyload-wrapper {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: rgba(#000000, .45);
        color: #000000;

        .lazyload-content {
            position: relative;
            min-height: 1px;
            flex: 0 0 auto;
            width: auto;
            max-width: none;
            align-self: center!important;
            text-align: center!important;
            cursor: pointer;
        }
    }
}

.text-label {
    font-weight: bold;
}

#panoramainterior {
    max-width: 100%;
    width: 576px;
    height: 432px;
}

.panoramainterior {
    width: 576px;
    height: 432px;
}

.spritespin-instance {
    max-width: 100%;
    min-height: 130px;
    /*width: 576px !important;
    height: 432px !important;*/
    padding-top: 56.25%;
}

.spritespin-canvas,
.spritespin-stage {
    height: unset !important;
}

.table-cell {
    display : table-cell;
    width: 100vw;
}

.table-body {
    display: table;
}

.center-content {
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
[ng-click]:not(.disabled){
    cursor: pointer;
}
[ng-click]:not(.disabled),
[data-ng-click]:not(.disabled),
[x-ng-click]:not(.disabled),
[ng-click]:not[disabled],
[data-ng-click]:not[disabled],
[x-ng-click]:not[disabled]{
    cursor: pointer;
}

.ratio4to3 {
    position: relative;
    width: 100%;
    padding-bottom: 75%;
    background: gold; /** <-- For the demo **/
}

.canvas-container {
    position: relative;
    width: 100% !important;
    height: auto !important;
}

.canvas-container canvas {
    position: absolute;
    width: 100% !important;
    height: auto !important;
}

.canvas-ratio-4to3 {
    padding-bottom: 75%;
}

.state-termsofuse code {
    border: none;
    padding: 0;
    font-size: unset;
    background-color: unset;
    color: unset;
    box-shadow: unset;
    font-family: unset;
}

.ext-icon {
    background-image: url(/././img/icon-ext.png);
    width: 18px;
    height: 16px;
    display: block;
    background-repeat: no-repeat;
}

.int-icon {
    background-image: url(/././img/icon-int.png);
    width: 17px;
    height: 16px;
    display: block;
    background-repeat: no-repeat;
}

textarea.form-control.textarea-styled {
    width: 100%;
    height: 120px;
    border: 1px solid #cccccc;
    padding: 5px;

}


.termsofuse-scrollable {
    overflow: scroll;
    max-height: calc(100vh - 265px);
}

.am-inaktive {
    user-select: none;
}

.am-active {
    background-color: #bf0000;
    color: white;
    user-select: none;
}

.padding-tr-10 {
    padding-left: 10px;
    padding-right: 10px;
}
.state-newjobClassic .upload-image{
    margin-top: 30px;

}
.job-guide-inactive{
    opacity: 0.3;
}

.form-group-slim.form-md-line-input{
    margin: 1px;
    padding: 1px;

}
#imgUpload .fileinput .thumbnail.place_logo_big{
    p{
        padding: 80px !important;
        font-weight: bold;
    }
}

// Job Guides

.table-text-align {
    vertical-align: middle !important;
}

.job-guides-overlay{
    margin-right: 10px;
    padding-left: 12px;
    padding-right: 12px;
}

.fixed-value{
    padding-top: 5px;
}

a.anchor {
    display: block;
    position: relative;
    top: -250px;
    visibility: hidden;
}

.hollow{
    visibility: hidden;
}

.job-guide-step {
    padding: 15px;
    color: grey;
    filter: grayscale(100%);
    opacity: 0.5;
}

.job-guide-step-active {
    color: #bf0000;
    filter: grayscale(0%);
    opacity: 1;
}

.job-guide-border {
    border-width: 1px;
    border-style: dashed;
    border-radius: 5px;

    &:hover {
        cursor: pointer;
    }
}

.job-guide-border-inner-spaces{
    padding: 5px;
    margin: 15px;
}

.job-guide-border-inner{
    border: 1px solid #bf0000;
    border-radius: 5px;
    position: relative;

    &:before {
        content: "";
        width: 11px;
        height: 17px;
        background: transparent;
        position: absolute;
        top: calc(50% - 7px);
        left: 5px;
        border-width: 3px;
        border-style: dotted;
        //cursor: pointer;
    }
}

.modal,
.ui-sortable-disabled {
    .job-guide-border-inner:before {
        content: none;
    }

    .job-guide-border:hover {
        cursor: default;
    }
}

.dropdown-toggle.btn:not(.md-skip):not(.bs-select-all):not(.bs-deselect-all) {
    box-shadow: none;
    width: 100%;
    height: 34px;
    padding: 6px 12px;
    font-size: 14px;
    line-height: 1.42857143;
    color: #555;
    background-color: #fff;
    background-image: none;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-weight: normal;
    text-transform: none;

    .filter-option {
        padding: 1px 19px 1px 4px;
    }

    .caret {
        right: 4px;
        margin-top: -11px !important;
        font-size: 16px;
    }

    .caret:before {
        //font-weight: bold;
        border-style: solid;
        border-width: 2px 2px 0 0;
        content: "";
        display: inline-block;
        height: 7px;
        right: 3px;
        position: relative;
        vertical-align: top;
        width: 7px;
        top: 6px;
        transform: rotate(135deg);
    }
}
#documentIframe{
    width: 100%;
    height: 900px;
    position: relative;
    margin: 0;
    padding: 0;
}
.errorMessage{
    color: red;
}
.message{
    text-align: center;
    margin: 20px auto;
}
.td-vehicle-actions{
    width: 160px;
    max-width: 160px;
}
.table-invoices-jobs {
    width: 100%;
    height: 100%;
    td:first-child{
        width: 80px;
    }
    td:last-child{
        width: 80px;
    }
}
.cell {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    box-sizing: border-box;
    display: block;
    padding: 8px;
    width: 100%;
}

.cell-overflow {
    box-sizing: border-box;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
